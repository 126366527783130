<!--
 * ======================================
 * 说明： 个人设置
 * 作者： Silence
 * 文件： setting.vue
 * 日期： 2023/8/31 1:56
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="form" label-width="80px" class="mt-4" :rules="rules">
      <el-form-item label="签名" prop="sign" v-if="false">
        <el-upload
          style="overflow: hidden"
          :limit="1"
          action="#"
          list-type="picture-card"
          :show-file-list="false"
          :http-request="onUploadSign"
        >
          <img v-if="form.sign" :src="form.sign" class="avatar w-full" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="印章" prop="seal" >
        <el-upload
          style="overflow: hidden"
          :limit="1"
          action="#"
          list-type="picture-card"
          :show-file-list="false"
          :http-request="onUploadSeal"
        >
          <img v-if="form.seal" :src="form.seal" class="avatar w-full h-full" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
          form:{
            teacherId:'',  // 教师id
            sign:'',       // 签名
            seal:'',       // 印章
          },
          rules:{
              // seal: [{ required: true, message: '请上传印章', trigger: 'blur' }],
              // sign: [{ required: true, message: '请上传签名', trigger: 'blur' }],
          }
      }
    },
    computed: {},
    created() {},
    mounted() {
      this.handleLoadForm();
    },
    methods: {
      handleLoadForm(){
        this.$cloud.get('account/detail').then(res=>{
            this.form.sign = res?.teacher?.sign
            this.form.seal = res?.teacher?.seal
            this.form.teacherId = res?.teacher?.id
        })
      },

      onUploadSign(file){
        let params = {
          file: file.file,
        };
        this.$cloud
          .upload("files/upload", params)
          .then((res) => {
            this.form.sign = res.url;
          })
      },

      onUploadSeal(file){
        let params = {
          file: file.file,
        };
        this.$cloud
          .upload("files/upload", params)
          .then((res) => {
            this.form.seal = res.url;
          })
      },

      // 保存
      onSubmit(){
        this.$cloud.post('teacher/upload',this.form).then(()=>{
            this.$message.success('保存成功');
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>
